import * as React from "react";
// tslint:disable-next-line:no-var-requires
import {
    AutocompleteInput,
    BooleanField,
    Datagrid,
    downloadCSV,
    Filter,
    List,
    NumberField,
    ReferenceField,
    ReferenceInput,
    SelectInput,
    TextField,
    TextInput
} from "react-admin";

import {StatusButton} from "../payments/StatusButton";
import {competitionConfig} from "../competitionConfig";
import {GroupedLocations} from "./GroupedLocations";
import jsonExport from 'jsonexport/dist';
import {locationGrouping, locations} from "../common/location-helper";
import {ListActions} from "../common/TopToolbar";

const AutocompleteOptions = {InputProps: {autoComplete: 'no'}}

const UserFilter = (props) => (
    <Filter {...props}>
        {!competitionConfig.isDuringAndAfterCompetition() &&  <SelectInput source="pickupLocation" choices={locations} alwaysOn/>}
        <TextInput source="id" label="Search through ids" alwaysOn/>
        <SelectInput source="status" alwaysOn choices={[{id: 'confirmed', name: 'Confirmed'}]}/>
        <ReferenceInput source="id" reference="users" label="Name"
                        filterToQuery={searchText => ({name: searchText})}
                        options={AutocompleteOptions}
                        allowEmpty={true} perPage={3}>
            <AutocompleteInput optionText="name" options={AutocompleteOptions}/>
        </ReferenceInput>
        <ReferenceInput source="id" reference="users" label="Email"
                        filterToQuery={searchText => ({email: searchText})}
                        options={AutocompleteOptions}
                        allowEmpty={true} perPage={3}>
            <AutocompleteInput optionText="email"
                               options={AutocompleteOptions}/>
        </ReferenceInput>
        <SelectInput source="randomTeam" alwaysOn choices={[
        {id: false, name: 'False'},
        {id: true, name: 'True'},
    ]}/>
    </Filter>
);

const exporter = (competitionUsers, fetchRelatedRecords) => {
    fetchRelatedRecords(competitionUsers, 'id', 'users').then(users => {
        const competitionData = competitionUsers.map(competitionUser => ({
            ...competitionUser,
            pickupLocation: locationGrouping(competitionUser.pickupLocation),
            name: users[competitionUser.id].name,
            email: users[competitionUser.id].email,
            company: users[competitionUser.id].company,
        }));


        if (!competitionConfig.isDuringAndAfterCompetition()) {
            fetchRelatedRecords(competitionUsers, 'id', `${competitionConfig.path}/user-payments`).then(payments => {
                const data = competitionData.map(competitionUser => ({
                    ...competitionUser,
                    package: payments[competitionUser.id].package
                }));

                jsonExport(data, {
                    headers: ['id', 'name', 'email', 'pickupLocation', "status", "package"] // order fields in the export
                }, (err, csv) => {
                    downloadCSV(csv, 'competition-users'); // download as 'posts.csv` file
                });
            });
        } else {
            jsonExport(competitionData, {
                headers: ['id', 'name', 'email',"package", 'company', "team", "totalSteps"] // order fields in the export
            }, (err, csv) => {
                downloadCSV(csv, 'competition-users'); // download as 'posts.csv` file
            });
        }
    });
};

export const CompetitionUsersList = (props) => {
    return <List {...props} filters={<UserFilter/>}
                 filterDefaultValues={competitionConfig.isDuringAndAfterCompetition() ? {status: 'confirmed'} : {}}
                 perPage={25}
                 exporter={exporter}
                 actions={<ListActions/>}
                 bulkActionButtons={false}>
        <Datagrid rowClick="show">
            <TextField source="id"/>
            <ReferenceField source="id" reference="users" label="Name" sortBy="user.name" link={false}
                            sortable={false}>
                <TextField source="name"/>
            </ReferenceField>
            <ReferenceField source="id"
                            reference="users"
                            label="Email"
                            allowEmpty="false"
                            sortable={false}>
                <TextField source="email"/>
            </ReferenceField>
            <ReferenceField source="id"
                            reference="users"
                            label="Company"
                            link={false}
                            sortable={false}>
                <TextField source="company"/>
            </ReferenceField>
            {competitionConfig.isDuringAndAfterCompetition() && <BooleanField source="companiesChallenge"/>}

            {!competitionConfig.isDuringAndAfterCompetition() &&
            <ReferenceField source="id"
                            reference={`${competitionConfig.path}/user-payments`}
                            label="2024"
                            allowEmpty link={() => ``}
                            sortBy="status"
                            sortable={false}>
                <StatusButton source="status"/>
            </ReferenceField>}

            {!competitionConfig.isDuringAndAfterCompetition() &&
            <ReferenceField source="id" reference="users"
                            label={`2023`} allowEmpty link={() => ``}
                            sortable={false}>
                <BooleanField source="participated.2023"/>
            </ReferenceField>}

            {!competitionConfig.isDuringAndAfterCompetition() &&
            <ReferenceField source="id" reference="users"
                            label={`2022`} allowEmpty link={() => ``}
                            sortable={false}>
                <BooleanField source="participated.2022"/>
            </ReferenceField>}

            {!competitionConfig.isDuringAndAfterCompetition() &&
            <ReferenceField source="id" reference="users"
                            label={`2021`} allowEmpty link={() => ``}
                            sortable={false}>
                <BooleanField source="participated.2021"/>
            </ReferenceField>}
            {!competitionConfig.isDuringAndAfterCompetition() &&
            <ReferenceField source="id" reference="users"
                            label={`2020`} allowEmpty link={() => ``}
                            sortable={false}>
                <BooleanField source="participated.2020"/>
            </ReferenceField>}
            {!competitionConfig.isDuringAndAfterCompetition() &&
            <ReferenceField source="id" reference="users"
                            label={`2019`} allowEmpty link={() => ``}
                            sortable={false}>
                <BooleanField source="participated.2019"/>
            </ReferenceField>}

            <ReferenceField source="id"
                            reference={`${competitionConfig.path}/user-payments`}
                            label={`Package`}
                            allowEmpty
                            sortable={false}
                            link={() => ``}>
                <TextField source="package"/>
            </ReferenceField>
            {!competitionConfig.isDuringAndAfterCompetition() &&
            <GroupedLocations source="pickupLocation"/>}
            <BooleanField source="randomTeam"/>
            <TextField source="team"/>
            {competitionConfig.isDuringAndAfterCompetition() && <NumberField source="totalSteps" emptyText={0}/>}
            {competitionConfig.isDuringAndAfterCompetition() && <BooleanField source="superwalker"/>}
        </Datagrid>
    </List>
};
