import {Link} from "@material-ui/core";
import {competitionConfig} from "../competitionConfig";

export const createOrEdit = (props) => {
    if (props.record["status"]) {
        return `#/${competitionConfig.path}/user-payments/${props.record.id}`
    }

    return `#/${competitionConfig.path}/user-payments/create?source=${JSON.stringify({id: props.record.id})}`
};
export const StatusButton = (props) => (
    <Link
        href={createOrEdit(props)}
        disabled={false}
    >
        {props.record["status"] || "not paid"}
    </Link>
);
