/**
 * Use for the pickupLocation filter.
 * id is consumed by the hazy search used in filtering. So a partial match will return true.
 * e.g. Courier and Curier ar matched by id 'urier'.
 * @type {[{name: string, id: string}, {name: string, id: string}, {name: string, id: string}, {name: string, id: string}, {name: string, id: string}, null]}
 */
export const locations = [
    {
        id: "The Office",
        name: "The Office"
    }, {
        id: "Iulius Mall",
        name: "BT Agenția Iulius Mall"
    }, {
        id: "Vivo",
        name: "BT Agenția Vivo"
    }, {
        id: "Mănăștur",
        name: "BT Agenția Mănăștur"
    }, {
        id: "urier", //typo in id is intended!
        name: "Curier"
    }
]

export const locationGrouping = (location) => {
    if (!location) {
        return "";
    }
    if (location.includes("The Office")) {
        return "The Office";
    }
    if (location.includes("Iulius Mall")) {
        return "BT Agenția Iulius Mall";
    }
    if (location.includes("Vivo")) {
        return "BT Agenția Vivo";
    }
    if (location.includes("Mănăștur")) {
        return "BT Agenția Mănăștur";
    }
    if (location.includes("urier")) {
        return "Curier";
    }

    return "";
};
