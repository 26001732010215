import {Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";

export const PaymentsTimelineChart = ({dailyPayments}) => {
    if (!dailyPayments) {
        return null;
    }
    let data = Object.entries(dailyPayments).sort().map((entry) => ({...entry[1]}))
    return <div style={{flex: 1, height: 300, maxWidth: 1100}}>
        <ResponsiveContainer width="100%" height="100%">
            <LineChart width={730} height={250} data={data} key={data.length}>
                <XAxis dataKey="day" format="number"/>
                <YAxis/>
                <Tooltip/>
                <Legend verticalAlign="top" height={36}/>
                <Line type="monotone" dataKey="total" stroke="#83f596"/>
                <Line type="monotone" dataKey="basic" stroke="#67a8e0"/>
                <Line type="monotone" dataKey="premium" stroke="#00C49F"/>
                <Line type="monotone" dataKey="vip" stroke="#FFBB28"/>
            </LineChart>
        </ResponsiveContainer>
    </div>
};
