import * as React from "react";
// tslint:disable-next-line:no-var-requires
import {Edit, ReferenceField, SimpleForm, TextInput} from "react-admin";
import {Typography} from "@material-ui/core";
import {StatusButton} from "../payments/StatusButton";
import {competitionConfig} from "../competitionConfig";

export const ActivationCodeEdit = (props) => {
    return <Edit {...props}>
        <SimpleForm>
            <Typography variant="h5">Activation code</Typography>

            <TextInput source="code" options={{disabled: true}}/>
            <TextInput source="name"/>
            <TextInput source="email"/>
            <TextInput source="company"/>
            <TextInput source="package"/>
        </SimpleForm>
    </Edit>
};
