export const mapPaymentToPackage = (amount, cause = "ro") => {

    switch (cause) {
        //TODO add cause for future years
    }


    switch (amount) {
        case '99.00':
            return 'basic';
            break;
        case '260.00':
            return 'premium';
            break;
        case '499.00':
            return 'vip';
            break;
    }
}

export const packageAmountList = [
    {id: 0, name: 'No payment'},
    {id: '99.00', name: '99.00 ron (Basic package)'},
    {id: '260.00', name: '260.00 ron (Premium package)'},
    {id: '499.00', name: '499.00 ron (Vip package)'},
]
export const packagesList = [
    {id: 'basic', name: 'Basic package'},
    {id: 'premium', name: 'Premium package'},
    {id: 'vip', name: 'Vip package'},
]
