export const firebaseConfig = {
    apiKey: "AIzaSyANOpsxfWjxU6czINz4QJLJtbPL4rERaQg",
    authDomain: "walkinthepark-3c783.firebaseapp.com",
    databaseURL: "https://walkinthepark-3c783.firebaseio.com",
    projectId: "walkinthepark-3c783",
    storageBucket: "walkinthepark-3c783.appspot.com",
    messagingSenderId: "732126244055",
    appId: "1:732126244055:web:e43dc66a7de2ec23c59492",
    measurementId: "G-6QVFMP5CD6"
  };
