import * as React from "react";
import {Admin, Login, Resource} from "react-admin";
import {FirebaseAuthProvider, FirebaseDataProvider} from "react-admin-firebase";

import {firebaseConfig as config} from './FIREBASE_CONFIG';
import {PaymentsList} from "./payments/PaymentsList";
import {TeamsList} from "./teams/TeamsList";
import {AllUsersList} from "./users/AllUsersList";
import {UserEdit} from "./users/UserEdit";
import {CompetitionUsersList} from "./users/CompetitionUsersList";
import {UserPaymentEdit} from "./payments/UserPaymentEdit";
import {UserPaymentCreate} from "./payments/UserPaymentCreate";
import {competitionConfig} from "./competitionConfig";
import {CompetitionUserEdit} from "./users/CompetitionUserEdit";
import {walkingMonthTheme} from "./walkingMonthTheme"
import {CssBaseline} from "@material-ui/core";
import {UserShow} from "./users/UserShow";
import {CompetitionUserShow} from "./users/CompetitionUserShow";
import {TeamEdit} from "./teams/TeamEdit";
import {TeamShow} from "./teams/TeamShow";
import {Dashboard} from "./dashboard/Dashboard";
import {TeamCreate} from "./teams/TeamCreate";
import {UserPaymentsList} from "./payments/UserPaymentsList";
import {ActivationCodesList} from "./activation-codes/ActivationCodesList";
import {ActivationCodeEdit} from "./activation-codes/ActivationCodeEdit";
import {firebaseApp} from "./firebase";
import {LayoutApp} from "./Layout";
import customRoutes from './customRoutes';


const options = {
    logging: false,
    app: firebaseApp,
    rootRef: '/',
    firestoreCostsLogger: {
        enabled: false,
    },
    lazyLoading: {
        enabled: false
    }
}

const dataProvider = FirebaseDataProvider(config, options);
const authProvider = FirebaseAuthProvider(config, options);

const adminAuthProvider = {
    // Copy all authprovider functionality
    ...authProvider,
    // Wrap the login and check for custom claims
    login: async (params) => {
        const user = await authProvider.login(params);
        // getPermissions is how when get the custom claims for the logged in user
        const claims = await authProvider.getPermissions();

        if (claims["admin"] === true) {
            return user;
        }

        // Make sure user is logged out, if not an admin
        await authProvider.logout();
        throw new Error("Login error, invalid permissions");
    },
    getPermissions: async () => {
        const claims = await authProvider.getPermissions();

        if (claims["admin"] === true) {
            return Promise.resolve("admin")
        }

        return Promise.reject();
    },
};


const App = () => (
    <Admin
        title="Walking Month - backoffice"
        loginPage={Login}
        layout={LayoutApp}
        dataProvider={dataProvider}
        authProvider={adminAuthProvider}
        theme={walkingMonthTheme}
        dashboard={Dashboard}
        customRoutes={customRoutes}
        disableTelemetry>
        {permissions => {
            if (permissions === 'admin') {
                return [
                    <CssBaseline/>,
                    <Resource name="users"
                              list={AllUsersList}
                              edit={UserEdit}
                              show={UserShow}
                    />,
                    <Resource
                        name={`${competitionConfig.path}/users`}
                        list={CompetitionUsersList}
                        edit={CompetitionUserEdit}
                        show={CompetitionUserShow}
                    />,
                    <Resource
                        name={`${competitionConfig.path}/teams`}
                        list={TeamsList}
                        edit={TeamEdit}
                        create={TeamCreate}
                        show={TeamShow}
                    />,

                    <Resource
                        name={`${competitionConfig.path}/payments`}
                        list={PaymentsList}/>,

                    <Resource name={`${competitionConfig.path}/user-payments`}
                              list={UserPaymentsList}
                              edit={UserPaymentEdit}
                              create={UserPaymentCreate}/>,

                    <Resource name={`${competitionConfig.path}/activation-codes`}
                              list={ActivationCodesList} 
                              edit={ActivationCodeEdit}
                              />
                ]
            } else {
                return <CssBaseline/>
            }
        }}
    </Admin>
);

export default App;
