import dayjs from "dayjs";
import {competitionConfig} from "../competitionConfig";

export const generateDays = () => {
    let startDate = dayjs(competitionConfig.startDate);//, "YYYY-MM-DD");
    let endDate = dayjs(competitionConfig.endDate);//, "YYYY-MM-DD");
    let diff = endDate.diff(startDate, "days");

    let dates = [];

    for (let i = 0; i <= diff; i++) {
        let date = startDate.add(i, "day");

        dates[date.format("YYYY-MM-DD")] = {
            id: date.format("YYYY-MM-DD"),
            date: date,
            steps: 0,
            edited: false
        };
    }

    return dates;
}
