import { Datagrid, TextField } from "react-admin";
import {
  List,
  Select,
  Button,
  MenuItem,
  Stepper,
  Step,
  StepLabel,
} from "@material-ui/core";
import { useState } from "react";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { firebaseDB as db } from "../firebase";

import "./style.scss";
import { competitionConfig, functionsEndpoint } from "../competitionConfig";

const temp = `ATU Consulting	Marica Lucian	maricalucian@gmail.com	basic`;
const temp2 = `ATU Consulting	Harlișcă Adela 	adela.harlisca@atuconsulting.ro	basic`;

const steps = ["Add codes data", "Verify and insert in db", "Send code emails"];

export const PostList = (props) => (
  <List {...props}>
    <Datagrid>
      <TextField source="id" />
      <TextField source="title" />
      <TextField source="body" />
      <EditButton />
    </Datagrid>
  </List>
);

const processData = (data, separator) => {
  const result = {
    error: false,
  };
  const lines = data.split("\n");

  if (lines.length < 1) {
    result.error = true;
    result.errorInfo = "Error processing data";
    return result;
  }

  const res = lines.map((l, i) => {
    const lineData = l.split(separator);

    const lineRes = {
      company: lineData[0].trim(),
      name: lineData[1].trim(),
      email: lineData[2].trim(),
      package: lineData[3].trim().toLowerCase(),
    };

    if (
      lineRes.company === "" ||
      lineRes.name === "" ||
      lineRes.email === "" ||
      (lineRes.package !== "basic" && lineRes.package !== "premium" && lineRes.package !== "vip")
    ) {
      result.error = true;
      result.errorInfo = "Error at line " + (i + 1);
    }

    return lineRes;
  });

  if (result.error) {
    return result;
  } else {
    result.data = res;
    return result;
  }
};

export const AddActivationCodes = () => {
  const [separator, setSeparator] = useState("\t");
  const [activeStep, setActiveStep] = useState(0);
  const [csvData, setCsvData] = useState('');
  const [lines, setLines] = useState([]);
  const [generating, setGenerating] = useState(false);
  const [emailSent, setEmailSent] = useState(false);

  return (
    <div className="activation-codes">
      <div className="info-bar">
        <div className="info-left">
          <Stepper
            activeStep={activeStep}
            style={{ backgroundColor: "#303030", height: "64px" }}
          >
            {steps.map((label, index) => {
              const stepProps = {};
              const labelProps = {};
              if (activeStep > index) {
                stepProps.completed = true;
              } else {
                stepProps.completed = false;
              }
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </div>
        <div className="info-right">
          <div className="action">
            {activeStep === 0 && (
              <Button
                variant="contained"
                color="primary"
                startIcon={<NavigateNextIcon />}
                onClick={() => {
                  const res = processData(csvData, separator);
                  if (res.error) {
                    alert(result.errorInfo);
                  } else {
                    setLines(res.data);
                    setActiveStep(1);
                  }
                }}
              >
                Process data
              </Button>
            )}
            {activeStep === 1 && (
              <Button
                variant="contained"
                disabled={generating}
                color={`primary`}
                startIcon={<NavigateNextIcon />}
                onClick={async () => {
                  setGenerating(true);
                  for (let i = 0; i < lines.length; i++) {
                    const l = lines[i];
                    const code = Array(6)
                      .fill("0123456789abcdefghijklmnopqrstuvwxyz")
                      .map(function (x) {
                        return x[Math.floor(Math.random() * x.length)];
                      })
                      .join("");
                    try {
                      await db
                        .collection(
                          `${competitionConfig.path}/activation-codes`
                        )
                        .doc(code)
                        .set({
                          name: l.name,
                          code: code,
                          email: l.email,
                          company: l.company,
                          package: l.package,
                        });
                      lines[i].code = code;
                    } catch (e) {
                      alert("Error generating codes");
                      break;
                    }
                  }
                  setLines(lines);
                  setActiveStep(2);
                  setGenerating(false);
                }}
              >
                {!generating && <>Looking good, generate</>}
                {generating && <>Generating codes...</>}
              </Button>
            )}
            {activeStep === 2 && (
              <Button
                variant="contained"
                color={`primary`}
                disabled={generating || emailSent}
                startIcon={<NavigateNextIcon />}
                onClick={async () => {
                  setGenerating(true);
                  for (let i = 0; i < lines.length; i++) {
                    // const res = await sendActivationCode(lines[i]);
                    await fetch(
                      `${functionsEndpoint}sendActivationCodeEmail?code=${lines[i].code}`
                    )
                      .then(async (response) => await response.json())
                      .then(async (data) => {
                        if(data.success) {
                          lines[i].success = true;
                          lines[i].error = false;
                        } else {
                          lines[i].success = false;
                          lines[i].error = data?.error;
                        }
                      });
                    setLines(lines);
                  }

                  setGenerating(false);
                }}
              >
                {!generating && !emailSent && <>Send emails</>}
                {generating && !emailSent && <>Sending emails...</>}
                {emailSent && <>Emails sent</>}
              </Button>
            )}
          </div>
        </div>
      </div>
      {activeStep === 0 && (
        <div className="page">
          <div className="table-top">
            <div className="instructions">
              Please paste the company users info as CSV in this format,
              separated by TAB or comma:
              <div className="code">Company{"\t"}Name{"\t"}Email{"\t"}Package</div>
            </div>
            <div className="separator">
              Separator:
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={separator}
                variant="filled"
                onChange={(e) => {
                  setSeparator(e.target.value);
                }}
              >
                <MenuItem value={"\t"}>TAB</MenuItem>
                <MenuItem value={","}>Comma</MenuItem>
              </Select>
            </div>
          </div>
          <textarea
            onChange={(e) => {
              setCsvData(e.target.value);
            }}
            value={csvData}
          />
        </div>
      )}
      {(activeStep === 1 || activeStep === 2) && (
        <div className="page">
          <div className="line line-header">
            <div style={{ width: "28px" }}></div>
            {activeStep === 2 && <div className="line-item-small">Code</div>}
            <div className="line-item">Company</div>
            <div className="line-item">Name</div>
            <div className="line-item">Email</div>
            <div className="line-item-small">Package</div>
          </div>
          {lines.map((l, i) => {
            return (
              <div
                className={`line ${activeStep === 2 && l.success && "saved"} ${activeStep === 2 && l.error && "error"}`}
                key={i}
              >
                <div style={{ width: "28px" }}>{i + 1}.</div>
                {activeStep === 2 && (
                  <div className="line-item-small">
                    <b>{l.code}</b>
                  </div>
                )}
                <div className="line-item">{l.company}</div>
                <div className="line-item">{l.name}</div>
                <div className="line-item">{l.email}</div>
                <div className="line-item-small">{l.package}</div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
