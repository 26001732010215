import {Cell, Pie, PieChart, ResponsiveContainer, Tooltip} from 'recharts';

const COLORS = ['#67a8e0', '#00C49F', '#FFBB28', '#FF8042'];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({cx, cy, midAngle, innerRadius, outerRadius, percent, index, name}) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    return (
        <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central" filter="drop-shadow(0px 0px 2px rgb(0 0 0 / 1))">
            {`${name}: ${(percent * 100).toFixed(0)}%`}
        </text>
    );
}

export const PackagesChart = ({packages}) => {
    if (!packages) {
        return null;
    }

    let data = Object.entries(packages).filter(entry => entry[0] !== 'undefined').map((entry) => ({name: entry[0], value: entry[1]}))


    return <div style={{flex: 1, height: 240, alignContent: 'flex-end'}}>
        <ResponsiveContainer width="100%" height="100%">
            <PieChart height={200}>
                <Pie
                    data={data}
                    cx="50%"
                    cy="50%"
                    labelLine={false}
                    label={renderCustomizedLabel}
                    outerRadius={100}
                    dataKey="value"
                    nameKey="name"
                    key={data.length}
                    stroke=""
                >
                    <Tooltip/>
                    {data.map((entry, index) =>
                        <Cell name={entry.name} key={`cell-${index}`} fill={COLORS[index % COLORS.length]}/>)
                    }
                </Pie>
            </PieChart>
        </ResponsiveContainer>
    </div>
}
