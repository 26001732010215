import * as React from "react";
// tslint:disable-next-line:no-var-requires
import {
    ArrayInput,
    AutocompleteInput,
    BooleanInput,
    DeleteButton,
    Edit,
    ReferenceInput,
    SaveButton,
    SelectInput,
    SimpleForm,
    SimpleFormIterator,
    TextInput,
    Toolbar,
    useDataProvider,
    useRedirect,
    useRefresh,
} from "react-admin";
import {Typography} from "@material-ui/core";
import {competitionConfig} from "../competitionConfig";
import {removeFromTeams} from "./team-helpers";
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles({
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
});
const CustomToolbar = props => (
    <Toolbar {...props} classes={useStyles()}>
        <SaveButton/>
        <DeleteButton undoable={false}/>
    </Toolbar>
);


export const TeamEdit = (props) => {
    const dataProvider = useDataProvider();

    const refresh = useRefresh();
    const redirect = useRedirect();
    let initialLeader;
    let initialTeamMembers = [];

    if (props.id) {
        dataProvider.getOne(
            `${competitionConfig.path}/teams`,
            {
                id: props.id
            }).then((team) => {
            if (team && team.data) {
                if (!initialLeader) {
                    initialLeader = team.data.leader;
                }
                if (!initialTeamMembers.length) {
                    team.data.members.forEach((member) => {
                        initialTeamMembers.push(member.uid);
                    })
                }
            }
        });
    }

    const onSuccess = async ({data}) => {
        let members = data?.members?.map((member) => member.uid) || [];
        let membersData = data?.members || [];
        let needsUpdate = false;

        /**
         *  Let's start with the leader
         *  */

        let currentLeaders = membersData.filter((member) => (member.leader === true));
        let currentLeader = currentLeaders.length ? currentLeaders[0] : null;

        // if no leader is set, then set first user in list
        if (!currentLeaders.length) {
            membersData[0] ? membersData[0].leader = true : null;

            currentLeader = membersData[0];

            needsUpdate = true;
        }

        //more leaders are set, only leave one;
        if (currentLeaders.length > 1) {
            membersData.forEach((member) => {
                if (member.uid !== currentLeader.uid) {
                    member.leader = false;
                }
            })

            needsUpdate = true;
        }

        // changed the leader
        if (initialLeader !== currentLeader.uid) {
            needsUpdate = true;
        }

        membersData.forEach((member) => {
            if (!member.confirmed) {
                member.confirmed = true;
                needsUpdate = true;
            }
        });

        if (needsUpdate) {
            const {data: updatedTeams} = await dataProvider.update(
                `${competitionConfig.path}/teams`,
                {
                    id: data.id,
                    data: {
                        members: membersData,
                        leader: currentLeader.uid
                    }
                });
        }

        /** let's update the members */
        let addedMembers = members.filter((member) => !initialTeamMembers.includes(member));
        let removedMembers = (initialTeamMembers || []).filter((member) => !members.includes(member));

        // set correct team on all members
        try {
            const {data: users} = await dataProvider.updateMany(
            `${competitionConfig.path}/users`,
            {
                ids: members,
                data: {
                    team: data.id
                },
            });

        } catch (e) {
            console.log(e);
            console.log("One of the users isn't in this year's competition.")
        }

        // remove team from removed members
        if (removedMembers.length) {
            try {
                const {data: users} = await dataProvider.updateMany(
                    `${competitionConfig.path}/users`,
                    {
                        ids: removedMembers,
                        data: {
                            team: null
                        },
                    }
                );
            } catch (e) {
                console.log(e);
                console.log("One of the users isn't in this year's competition.")
            }
        }

        redirect(`/${competitionConfig.path}/teams/${data.id}/show`);
        refresh();
    };

    //TODO team delete flow

    return <Edit {...props} onSuccess={onSuccess} mutationMode="pessimistic">
        <SimpleForm toolbar={<CustomToolbar/>}>
            <Typography variant="h5">Team details</Typography>

            <TextInput source="id" disabled={true}/>
            <ArrayInput label="Members" source="members">
                <SimpleFormIterator>
                    <TextInput source="uid" label="Id"/>
                    <ReferenceInput source="uid" reference="users" label="Name"
                                    filterToQuery={searchText => ({name: searchText})}
                                    allowEmpty={true}>
                        <AutocompleteInput optionText="name"/>
                    </ReferenceInput>
                    <ReferenceInput source="uid" reference="users" label="email"
                                    filterToQuery={searchText => ({email: searchText})}
                                    allowEmpty={true}>
                        <AutocompleteInput optionText="email"/>
                    </ReferenceInput>
                    <BooleanInput key="uid" source="confirmed" label="Confirmed"/>
                    <BooleanInput key="uid" source="leader" label="Leader"/>
                    <ReferenceInput source="uid"
                                    reference={`${competitionConfig.path}/users`}
                                    label="Member team"
                                    disabled={true}
                                    allowEmpty={true}>
                        <SelectInput optionText="team" source="team"/>
                    </ReferenceInput>
                </SimpleFormIterator>
            </ArrayInput>
        </SimpleForm>
    </Edit>
};
