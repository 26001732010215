import * as React from "react";
import {useCallback} from "react";
// tslint:disable-next-line:no-var-requires
import {
    ArrayInput,
    AutocompleteInput,
    BooleanInput,
    Create,
    minLength,
    ReferenceInput,
    required,
    SelectInput,
    SimpleForm,
    SimpleFormIterator,
    TextInput,
    useDataProvider,
    useMutation,
    useRedirect,
    useRefresh
} from "react-admin";
import {Typography} from "@material-ui/core";
import {checkTeamName, removeFromTeams} from "./team-helpers";
import {competitionConfig} from "../competitionConfig";

export const TeamCreate = (props) => {
    const validateTeamName = [required(), minLength(3), checkTeamName]

    const dataProvider = useDataProvider();

    const [mutate] = useMutation();

    const refresh = useRefresh();
    const redirect = useRedirect();

    const onSave = useCallback(
        async (values) => {
            let members = values?.members?.map((member) => member.uid) || [];
            let membersData = values?.members || [];

            values.name = values.id;

            /**
             *  Let's start with the leader
             *  */

            let currentLeaders = membersData.filter((member) => (member.leader === true));
            let currentLeader = currentLeaders.length ? currentLeaders[0] : null;

            // if no leader is set, then set first user in list
            if (!currentLeaders.length) {
                membersData[0] ? membersData[0].leader = true : null;
                currentLeader = membersData[0];
            }

            //more leaders are set, only leave one;
            if (currentLeaders.length > 1) {
                membersData.forEach((member) => {
                    if (member.uid !== currentLeader.uid) {
                        member.leader = false;
                    }
                })
            }
            values.leader = currentLeader.uid;

            membersData.forEach((member) => {
                if (!member.confirmed) {
                    member.confirmed = true;
                }
            });

            //if the users already have a team assigned, then remove from those teams
            await removeFromTeams(members, dataProvider);

            try {
                await mutate({
                    type: 'create',
                    resource: `${competitionConfig.path}/teams`,
                    payload: {
                        data: values
                    },
                }, {returnPromise: true});
            } catch (error) {
                if (error.body.errors) {
                    return error.body.errors;
                }
            }
            //set team on each user
            try {
                const {data: users} = await dataProvider.updateMany(
                    `${competitionConfig.path}/users`,
                    {
                        ids: members,
                        data: {
                            team: values.id
                        },
                    })
                
                redirect(`${competitionConfig.path}/teams/`);
                refresh();
            } catch (e) {
                alert("User isn't in this year's competition")
                console.log(e);
                console.log("User isn't in this year's competition")
            }

        },
        [mutate],
    );


    return <Create {...props} mutationMode="pessimistic">
        <SimpleForm save={onSave}>
            <Typography variant="h5">Team details</Typography>

            <TextInput source="id" validate={validateTeamName}/>
            <ArrayInput label="Members" source="members">
                <SimpleFormIterator>
                    <TextInput source="uid" label="Id"/>
                    <ReferenceInput source="uid" reference="users" label="Name"
                                    filterToQuery={searchText => ({name: searchText})}
                                    allowEmpty={true}>
                        <AutocompleteInput optionText="name"/>
                    </ReferenceInput>
                    <ReferenceInput source="uid" reference="users" label="email"
                                    filterToQuery={searchText => ({email: searchText})}
                                    allowEmpty={true}>
                        <AutocompleteInput optionText="email"/>
                    </ReferenceInput>
                    <BooleanInput source="confirmed" label="Confirmed"/>
                    <BooleanInput source="leader" label="Leader"/>
                    <ReferenceInput source="uid"
                                    reference={`${competitionConfig.path}/users`}
                                    label="Member team"
                                    disabled={true}
                                    allowEmpty={true}>
                        <SelectInput optionText="team" source="team"/>
                    </ReferenceInput>
                </SimpleFormIterator>
            </ArrayInput>
        </SimpleForm>
    </Create>
};
