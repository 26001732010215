import * as React from "react";
// tslint:disable-next-line:no-var-requires
import {DateField} from "react-admin";


export const DateFieldForTimestamp = props => {
    const recordWithTimestampAsInteger = {
        [props.source]:
            new Date(props.record[props.source].replace(
                /^(\d{4})(\d\d)(\d\d)(\d\d)(\d\d)(\d\d)$/,
                '$4:$5:$6 $2/$3/$1'
            ))
    };
    return <DateField {...props} record={recordWithTimestampAsInteger} showTime={true}/>
}
