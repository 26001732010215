import * as React from "react";
// tslint:disable-next-line:no-var-requires
import {Edit, ReferenceField, SimpleForm, TextInput} from "react-admin";
import {Typography} from "@material-ui/core";
import {StatusButton} from "../payments/StatusButton";
import {competitionConfig} from "../competitionConfig";

export const UserEdit = (props) => {
    return <Edit {...props}>
        <SimpleForm>
            <Typography variant="h5">User details</Typography>

            <TextInput source="id" options={{disabled: true}}/>
            <TextInput source="name"/>
            <TextInput source="email"/>
            <TextInput source="phone"/>
            <TextInput source="occupation"/>
            <TextInput source="company"/>

            <ReferenceField source="id" reference={`${competitionConfig.path}/users`}
                            label={`${competitionConfig.year} status`} allowEmpty link={() => ``}>
                <StatusButton source="status"/>
            </ReferenceField>
        </SimpleForm>
    </Edit>
};
