import * as React from "react";
import {Component} from "react";
// tslint:disable-next-line:no-var-requires
import {firebaseDB} from "../firebase";
import {Button, Card, CardContent, Grid, TextField, Typography} from '@material-ui/core';
import {makeStyles} from "@material-ui/core/styles";
import {generateDays} from "./utils";
import SaveIcon from '@material-ui/icons/Save';
import firebase from "firebase";


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    control: {
        padding: theme.spacing(2),
    },
}));

class StepsEditComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {elements: [], dates: [], isChanged: false};
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange = (e) => {
        if (e?.target.name) {
            this.setState((prevState) => {
                let st = {...prevState};
                st.dates[e?.target.name].steps = parseInt(e.target.value);
                st.isChanged = true;
                return st;
            });
        }
    }
    componentDidMount = async () => {
        let db = firebaseDB;
        let dates = generateDays();
        let days = await db.collection(`${this.props.basePath}/${this.props.id}/steps/`).get();
        days.forEach((day) => {
            if (dates[day.id]) {
                dates[day.id].steps = day.data()?.steps || 0;
                dates[day.id].edited = day.data()?.edited || false;
            }
        });

        this.setState({dates: dates})
    }

    saveSteps = async () => {
        let db = firebaseDB;
        this.setState({isChanged: false});
        let daysRef = db.collection(`${this.props.basePath}/${this.props.id}/steps/`);
        let days = await daysRef.get();
        let daysMap = {};

        days.forEach((day) => {
            daysMap[day.id] = day.data();
        });
        let batch = db.batch();
        Object.keys(this.state.dates).map(async (key) => {
            if ((this.state.dates[key].steps != daysMap?.[key]?.steps) && this.state.dates[key].steps > 0) {
                batch.set(daysRef.doc(key), {
                    steps: this.state.dates[key].steps,
                    lastSynced: firebase.firestore.Timestamp.fromDate(new Date()),
                    edited: true,
                    backoffice: true,
                }, {merge: true});
            }
        });
        await batch.commit();
    }

    render() {
        return <Grid
            container
            direction="row"
            spacing={2}
            justifyContent="flex-start"
            alignItems="flex-start"
            style={{marginTop: 3}}
        >
            {Object.keys(this.state.dates).map(key => {
                return <Card style={{padding: 2, margin: 4, background: '#616161',}}>
                    <CardContent>
                        <Typography variant="subtitle">Day: </Typography>
                        <Typography variant="subtitle">{key}</Typography>
                        <div>
                            <TextField id="steps" label="Steps"
                                       name={key}
                                       defaultValue={this.state.dates[key]?.steps || 0} key={key}
                                       onChange={this.handleChange}
                                       type="number"
                            />
                        </div>
                        <Typography
                            variant="subtitle2">Edited: {(this.state.dates[key]?.edited || false).toString()}
                        </Typography>
                    </CardContent>
                </Card>
            })}
            <Grid item xs={12}>
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<SaveIcon/>} disabled={!this.state.isChanged} onClick={this.saveSteps}>
                    Save step changes
                </Button>
            </Grid>
        </Grid>;
    }
}

export const StepsEdit = props => (<StepsEditComponent {...props}/>);
