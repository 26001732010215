import {ExportButton, sanitizeListRestProps, TopToolbar} from "react-admin";
import {cloneElement} from "react";

export const ListActions = (props) => {
    const {
        className,
        filters,
        maxResults,
        ...rest
    } = props;

    return (
        <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
            {filters ? cloneElement(filters, {context: 'button'}) : ''}

            <ExportButton maxResults={4000}/>
        </TopToolbar>
    );
};
