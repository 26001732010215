import { Link } from "@material-ui/core";
import { competitionConfig } from "../competitionConfig";
import Button from "@material-ui/core/Button";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import { useState } from "react";
import { functionsEndpoint } from "../competitionConfig";
import { useRefresh } from 'react-admin';

export const SendEmailButton = ({ record }) => {
  const refresh = useRefresh();
  const [sending, setSending] = useState(false);
  const [sent, setSent] = useState(false);
  const [error, setError] = useState(false);

  return (
    <Button
      color="primary"
      startIcon={<MailOutlineIcon />}
      disabled={sending || sent || error}
      onClick={async () => {
        setSending(true);
        setError(false);
        setSent(false);
        await fetch(
          `${functionsEndpoint}sendActivationCodeEmail?code=${record.id}`
        )
          .then(async (response) => await response.json())
          .then(async (data) => {
            setSending(false);
            if (data.success) {
              setSent(true);
              setTimeout(() => {
                setSent(false);
                refresh();
              }, 2000);
            } else {
              setError(true);
              setTimeout(() => {
                setError(false);
                refresh();
              }, 2000);
            }
          });
      }}
    >
      {!sent && !sending && !error && `Send Email`}
      {sending && `Sending`}
      {sent && `Email Sent`}
      {error && `Error`}
    </Button>
  );
};
