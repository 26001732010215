import { Link } from "@material-ui/core";
import { competitionConfig } from "../competitionConfig";
import Button from "@material-ui/core/Button";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import { useState } from "react";
import { useRefresh } from "react-admin";

export const CompetitionUserLink = ({ record }) => {
  if (record.user) {
    return (
      <Link
        href={`#/${competitionConfig.path}/users/${record.user}`}
        disabled={false}
      >
        {record.user}
      </Link>
    );
  } else {
    return <span />;
  }
};
