import * as React from "react";
// tslint:disable-next-line:no-var-requires
import {Datagrid, List, ReferenceField, SelectInput, TextField, TextInput} from "react-admin";
import {packageAmountList} from "../common/packages-helper";
import {ListActions} from "../common/TopToolbar";

const UserFilter = [
    <TextInput source="id" label="Search through ids" alwaysOn/>,
    <SelectInput
        source="processedAmount"
        choices={packageAmountList}
        alwaysOn/>,
    // <AutocompleteInput label="Response Error Code" optionText="responseErrorCode" alwaysOn/>
];

export const UserPaymentsList = (props) => (
    <List {...props}
          filters={UserFilter}
          perPage={25}
          bulkActionButtons={false}
          actions={<ListActions/>}>
        <Datagrid rowClick="edit">
            <TextField source="id" label="User id"/>
            <ReferenceField source="id" reference="users" label="Name" link={false}>
                <TextField source="name"/>
            </ReferenceField>
            <ReferenceField source="id" reference="users" label="Email" allowEmpty="false" link={false}>
                <TextField source="email"/>
            </ReferenceField>
            <TextField source="processedAmount"/>
            <TextField source="status"/>
            <TextField source="package"/>
            <TextField source="createdby" label="Created by"/>
        </Datagrid>
    </List>
);
