import {ListItemText} from "@material-ui/core";

export const PickupLocationListItem = ({state, location}) => {
    const pl = state?.pickupLocations && state?.pickupLocations[location];
    const perLocation = state?.packagesPerLocation && state?.packagesPerLocation[location];

    return (<ListItemText primary={`${location}: ${pl || 0}`}
                          secondary={`premium: ${perLocation?.premium || 0} 
                            | vip: ${perLocation?.vip || 0} `}/>);
}
