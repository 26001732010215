import { Link } from "@material-ui/core";
import { competitionConfig } from "../competitionConfig";
import Button from "@material-ui/core/Button";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import { useState } from "react";
import { Labeled } from "react-admin";

export const UserDelilveryAddress = ({ record }) => {
  if (record.pickupMethod === "courier") {
    return (
      <Labeled label="Delivery address">
        <div>
          <div>{record?.deliveryAddress?.name}</div>
          <div>
            {record?.deliveryAddress?.address}, {record?.deliveryAddress?.city}
          </div>
          <div>County {record?.deliveryAddress?.county}</div>
          <div>Zip code {record?.deliveryAddress?.zip}</div>
          <div>{record?.deliveryAddress?.phone}</div>
        </div>
      </Labeled>
    );
  } else {
    return null;
  }
};
