import * as React from "react";
// tslint:disable-next-line:no-var-requires
import {Datagrid, List, ReferenceField, SelectInput, TextField, TextInput} from "react-admin";
import {DateFieldForTimestamp} from "../common/DateFieldForTimestamp";
import {packageAmountList} from "../common/packages-helper";
import {ListActions} from "../common/TopToolbar";

const UserFilter = [
    <TextInput source="id" label="Search through ids" alwaysOn/>,
    <SelectInput
        source="processedAmount"
        choices={packageAmountList}
        alwaysOn/>,
    // <AutocompleteInput label="Response Error Code" optionText="responseErrorCode" alwaysOn/>
];

export const PaymentsList = (props) => (
    <List {...props} filters={UserFilter} sort={{field: 'responseTimestamp', order: 'DESC'}} perPage={25}
          bulkActionButtons={false}
          actions={<ListActions/>}>
        <Datagrid>
            <TextField source="id" label="Payment id"/>
            <TextField source="customerId" label="Customer id"/>
            <ReferenceField source="customerId" reference="users" label="Name" link={false}>
                <TextField source="name"/>
            </ReferenceField>
            <ReferenceField source="customerId" reference="users" label="Email" allowEmpty="false" link={false}>
                <TextField source="email"/>
            </ReferenceField>
            <TextField source="processedAmount"/>
            <TextField source="responseAction"/>
            <TextField source="responseErrorCode"/>
            <DateFieldForTimestamp source="responseTimestamp"/>
        </Datagrid>
    </List>
);
