import * as React from "react";
import {Component} from "react";
// tslint:disable-next-line:no-var-requires
import {firebaseDB} from "../firebase";
import {Card, CardContent, Grid, Typography} from '@material-ui/core';
import {makeStyles} from "@material-ui/core/styles";
import {generateDays} from "./utils";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    control: {
        padding: theme.spacing(2),
    },
}));


class StepsListComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {elements: [], dates: []};
    }

    async componentDidMount() {
        let db = firebaseDB;
        let dates = generateDays();
        let days = await db.collection(`${this.props.basePath}/${this.props.id}/steps/`).get();
        days.forEach((day) => {
            if (dates[day.id]) {
                dates[day.id].steps = day.data()?.steps || 0;
                dates[day.id].edited = day.data()?.edited || false;
            }
        });

        this.setState({dates: dates})
    }

    render() {
        return <Grid
            container
            direction="row"
            spacing={2}
            justifyContent="flex-start"
            alignItems="flex-start"
            style={{marginTop: 3}}
        >
            {Object.keys(this.state.dates).map(key => {
                return <Card style={{padding: 2, margin: 4, background: '#616161',}}>
                    <CardContent>
                        <Typography variant="subtitle"><b>Day:</b> {key}</Typography>
                        <Typography variant="subtitle2"><b>Steps:</b> {this.state.dates[key]?.steps || 0}</Typography>
                        <Typography
                            variant="subtitle2"><b>Edited:</b> {(this.state.dates[key]?.edited || false).toString()}
                        </Typography>
                    </CardContent>
                </Card>
            })}
        </Grid>;
    }
}

export const StepsList = props => (
    <StepsListComponent {...props} />
);
