import * as React from "react";
// tslint:disable-next-line:no-var-requires
import {BooleanField, Datagrid, List, SelectInput, ReferenceField, TextField, TextInput} from "react-admin";
import {StatusButton} from "../payments/StatusButton";
import {competitionConfig, isAfterCompetition} from "../competitionConfig";
import {Chip, Link} from "@material-ui/core";
import {Text} from "recharts";

const UserFilter = [
    <TextInput source="id" label="Search id" alwaysOn/>,
    <TextInput source="name" label="Search name" alwaysOn/>,
    <TextInput source="email" label="Search email" alwaysOn/>,
    <TextInput source="loginEmail" label="Search login" alwaysOn/>,
    <TextInput source="company" label="Search company" alwaysOn/>,
    <SelectInput source="deleted" choices={[
        {id: false, name: 'Active'},
        {id: true, name: 'Deleted'},
    ]}
    alwaysOn
    label="Active accounts"/>
];

export const AllUsersList = (props) => (
    <List {...props} filters={UserFilter} perPage={25} bulkActionButtons={false} filterDefaultValues={{deleted: false}}>
        <Datagrid rowClick="show">
            <TextField source="id"/>
            <TextField source="name"/>
            <TextField source="email"/>
            <TextField source="loginEmail"/>
            <TextField source="company"/>
            <ReferenceField source="id" reference={`${competitionConfig.path}/users`}
                            label={`${competitionConfig.year}`} allowEmpty link={() => ``}
                            sortable={false}>
                <StatusButton source="status"/>
            </ReferenceField>
            {!isAfterCompetition() && <BooleanField label="2023" source="participated.2023"/>}
            {!isAfterCompetition() && <BooleanField label="2022" source="participated.2022"/>}
            {!isAfterCompetition() && <BooleanField label="2021" source="participated.2021"/>}
            {!isAfterCompetition() && <BooleanField label="2020" source="participated.2020"/>}
            {!isAfterCompetition() && <BooleanField label="2019" source="participated.2019"/>}

            <ReferenceField source="id" reference={`${competitionConfig.path}/users`}
                            label="Steps" allowEmpty link={(record) => `/${competitionConfig.path}/users/${record.id}/show/2`}
                            sortable={false}>
                    <Chip label={`${competitionConfig.year} Steps`} />
            </ReferenceField>

        </Datagrid>
    </List>
);
