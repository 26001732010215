import * as React from "react";
// tslint:disable-next-line:no-var-requires
import {
  Datagrid,
  List,
  Labeled,
  EditButton,
  SelectInput,
  TextField,
  TextInput,
  TopToolbar,
  Link,
} from "react-admin";
import { SendEmailButton } from "./SendEmailButton";
import { CompetitionUserLink } from "./CompetitionUserLink";
import Button from "@material-ui/core/Button";

const UserFilter = [
  <SelectInput
    source="used"
    label="Code used"
    choices={[
      { id: true, name: "Used" },
      { id: false, name: "Not used" },
    ]}
    alwaysOn
  />,
  <SelectInput
    source="emailSent"
    label="Email sent"
    choices={[
      { id: true, name: "Sent" },
      { id: false, name: "Not sent" },
    ]}
    alwaysOn
  />,
  <TextInput source="name" label="Search name" alwaysOn />,
  <TextInput source="company" label="Search company" alwaysOn />,
  <SelectInput
    source="package"
    label="Package"
    choices={[
      { id: "basic", name: "Basic" },
      { id: "premium", name: "Premium" },
      { id: "vip", name: "Vip" },
    ]}
    alwaysOn
  />,
];

export const ActivationCodesList = (props) => (
  <>
  <TopToolbar>
        <Link to="/add-activation-codes">
          <Button color="primary">Add activation codes</Button>
        </Link>
      </TopToolbar>
  <List
    {...props}
    filters={UserFilter}
    //   sort={{field: 'responseTimestamp', order: 'DESC'}}
    perPage={25}
    bulkActionButtons={false}
  >
    <Datagrid>
      <TextField source="id" label="Code" />
      <CompetitionUserLink label={"Used by"} />
      <TextField source="name" />
      <TextField source="company" />
      <TextField source="email" />
      <TextField source="emailSent" />
      <TextField source="package" />
      <EditButton />
      <SendEmailButton />
    </Datagrid>
  </List>
  </>
);
