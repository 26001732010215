import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

ReactDOM.render(<App/>, document.getElementById('root'));

// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register({
    onUpdate: (registration) => {
        const waitingServiceWorker = registration.waiting;

        if (waitingServiceWorker) {
            waitingServiceWorker.addEventListener("statechange", (event) => {
                if (event.target.state === "activated") {
                    if (
                        window.confirm(
                            "There is a new version of the backoffice ready. Please reload to update."
                        )
                    ) {
                        window.location.reload();
                    }
                }
            });
            waitingServiceWorker.postMessage({type: "SKIP_WAITING"});
        }
    },
});
