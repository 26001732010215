import React, {useCallback, useEffect, useState,} from 'react';
import {useDataProvider, useVersion} from "react-admin";
import {Card, CardContent, CardHeader, Grid, List, ListItem, ListItemText, Typography} from "@material-ui/core";
import {competitionConfig} from "../competitionConfig";
import {PickupLocationListItem} from "./PickupLocationListItem";
import {PackagesChart} from "./PackagesChart";
import {PaymentsTimelineChart} from "./PaymentsTimelineChart";
import {locationGrouping} from "../common/location-helper";
import {mapPaymentToPackage} from "../common/packages-helper";


const StatsGrid = () => {
    const [state, setState] = useState();
    const version = useVersion();
    const dataProvider = useDataProvider();
    const isDuringAndAfterCompetition = competitionConfig.isDuringAndAfterCompetition();

    /**
     * compute different statistics:
     * - nb of paid packages
     * - stats per package
     * - stats per location
     * @type {(function(): Promise<void>)|*}
     */
    const fetchData = useCallback(async () => {
        const {data: competition} = await dataProvider.getOne(
            `competitions`, { id: competitionConfig.name });

        const competitionPackages = competition.packages.reduce((packages, pack) => {
            packages[pack.type] = pack;
            return packages;
        }, {})

        const {data: competitionUsers} = await dataProvider.getList(
            `${competitionConfig.path}/users`, {
                pagination: {page: 1, perPage: 4000}
            });

        const competitionData = competitionUsers.reduce(
            (stats, competitionUser) => {
                if (competitionUser.status === 'confirmed') {
                    stats.nbConfirmed++;

                    let pickupLocation = locationGrouping(competitionUser.pickupLocation) || "no pickup needed";
                    if (!stats.pickupLocations[pickupLocation]) {
                        stats.pickupLocations[pickupLocation] = 0;
                    }
                    stats.pickupLocations[pickupLocation]++;

                    if (competitionUser.randomTeam && !competitionUser.team) {
                        stats.randomTeam++;
                    }
                    if (competitionUser.team) {
                        stats.hasTeam++;
                    } else {
                        stats.noTeam++;
                    }

                    if(competitionUser.companiesChallenge) {
                        stats.nbCompaniesChallenge++;
                    }

                    if (competitionUser.totalSteps) {
                        stats.totalSteps += competitionUser.totalSteps;
                    }
                } else {
                    stats.nbNotPaid++;
                }
                return stats;
            },
            {
                nbNotPaid: 0,
                nbConfirmed: 0,
                noTeam: 0,
                randomTeam: 0,
                hasTeam: 0,
                totalSteps: 0,
                nbCompaniesChallenge: 0,
                pickupLocations: {},
            }
        );
        setState(state => ({
            ...state,
            nbOfUsers: competitionData.nbNotPaid + competitionData.nbConfirmed,
            nbConfirmed: competitionData.nbConfirmed,
            nbNotPaid: competitionData.nbNotPaid,
            nbCompaniesChallenge: competitionData.nbCompaniesChallenge,
            pickupLocations: competitionData.pickupLocations,
            noTeam: competitionData.noTeam,
            hasTeam: competitionData.hasTeam,
            randomTeam: competitionData.randomTeam,
            totalSteps: competitionData.totalSteps,
            competitionPackages: competitionPackages,
        }));

        const {data: teams} = await dataProvider.getList(
            `${competitionConfig.path}/teams`, {
                pagination: {page: 1, perPage: 4000}
            });

        const teamStats = teams.reduce(
            (stats, team) => {
                stats.totalTeams++;
                stats.totalCompetitionSteps += team.totalSteps;

                return stats;
            }, {
                totalTeams: 0,
                totalCompetitionSteps: 0
            });

        setState(state => ({
            ...state,
            totalTeams: teamStats.totalTeams,
            totalCompetitionSteps: teamStats.totalCompetitionSteps,
        }));

        if (!isDuringAndAfterCompetition) {
            let competitionUsersMap = [];
            competitionUsers.map((user) => competitionUsersMap[user.id] = user);

            const {data: userPayments} = await dataProvider.getMany(
                `${competitionConfig.path}/user-payments`,
                {
                    ids: competitionUsers.map(
                        (user) => user.id),
                });
            const userPaymentData = userPayments.reduce(
                (stats, payment) => {
                    if (payment.status === 'confirmed') {
                        const user = competitionUsersMap[payment.id];
                        let pickupLocation = locationGrouping(user.pickupLocation)
                        stats.packages[payment.package]++;

                        if (pickupLocation) {
                            stats.packagesPerLocation[pickupLocation] = stats.packagesPerLocation[pickupLocation] || {
                                premium: 0,
                                vip: 0
                            }
                            stats.packagesPerLocation[pickupLocation][payment.package]++;
                        }
                    }
                    return stats;
                },
                {
                    packages: {
                        basic: 0,
                        premium: 0,
                        vip: 0
                    },
                    packagesPerLocation: {}
                }
            )

            setState(state => ({
                ...state,
                packages: userPaymentData.packages,
                packagesPerLocation: userPaymentData.packagesPerLocation
            }));

            const {data: payments} = await dataProvider.getList(
                `${competitionConfig.path}/payments`, {
                    pagination: {page: 1, perPage: 4000}
                }
            );

            const dailyPayments = payments.reduce(
                (stats, payment) => {
                    if (payment.responseAction === 'confirmed') {
                        //use dayjs
                        /** parse timestamp and generate date **/
                        let day = new Date(payment.responseTimestamp.replace(
                            /^(\d{4})(\d\d)(\d\d)(\d\d)(\d\d)(\d\d)$/,
                            '$4:$5:$6 $2/$3/$1'
                        ));

                        /**
                         * mm-dd
                         */
                        let dayFormat = `${String(day.getMonth() + 1).padStart(2, '0')}-${String(day.getDate()).padStart(2, '0')}`;

                        let packageOption = mapPaymentToPackage(payment.processedAmount);

                        if (!stats[dayFormat]) {
                            stats[dayFormat] = {
                                day: dayFormat,
                                total: 0,
                                basic: 0,
                                premium: 0,
                                vip: 0
                            }
                        }

                        stats[dayFormat][packageOption]++;
                        stats[dayFormat].total++;
                    }
                    return stats;
                }, {});

            setState(state => ({
                ...state,
                dailyPayments: dailyPayments
            }));
        } else {

        }

    }, [dataProvider]);

    useEffect(() => {
        fetchData();
    }, [version]);

    const styles = {
        flex: {display: 'flex'},
        flexColumn: {display: 'flex', flexDirection: 'column'},
        leftCol: {flex: 1, marginRight: '0.5em'},
        rightCol: {flex: 1, marginLeft: '0.5em'},
        singleCol: {marginTop: '1em', marginBottom: '1em'},
        cardStyle: {
            width: '100%',
            margin: '0.5em',
            display: 'inline-block',
            verticalAlign: 'top'
        }
    };
    return (
        <div>
            <div style={styles.flex}>
                <div style={styles.leftCol}>
                    <div style={styles.flex}>
                        <Card
                            style={styles.cardStyle}>
                            <CardHeader title="Participants"></CardHeader>
                            <CardContent>
                                <Grid>
                                    <Typography><b>Total users:</b> {Intl.NumberFormat('ro-RO').format(state?.nbOfUsers || 0)}</Typography>

                                    <Typography><b>Paying users:</b> {Intl.NumberFormat('ro-RO').format(state?.nbConfirmed || 0)}</Typography>
                                    <Typography variant='small'>including manual payments AND company challenge</Typography>

                                    <Typography><b>Companies challenge users:</b> {Intl.NumberFormat('ro-RO').format(state?.nbCompaniesChallenge || 0)}</Typography>
                                    <Typography variant='small'>including activation codes</Typography>

                                    {/* <Typography variant='small'>including BfRD </Typography> */}

                                </Grid>
                            </CardContent>
                        </Card>
                        <Card
                            style={styles.cardStyle}>
                            <CardHeader title="Teams"></CardHeader>
                            <CardContent>
                                <Grid>
                                    <Typography><b>Total teams:</b> {Intl.NumberFormat('ro-RO').format(state?.totalTeams || 0)}</Typography>
                                    <Typography><b>Users without team:</b> {Intl.NumberFormat('ro-RO').format(state?.noTeam || 0)}</Typography>
                                    <Typography><b>Users w/ random team set:</b> {Intl.NumberFormat('ro-RO').format(state?.randomTeam || 0)}</Typography>
                                    <Typography><b>Users w/ teams:</b> {Intl.NumberFormat('ro-RO').format(state?.hasTeam || 0)}</Typography>
                                </Grid>
                            </CardContent>
                        </Card>
                    </div>

                    <div style={styles.flex}>
                        {!isDuringAndAfterCompetition && <Card style={styles.cardStyle}>
                            <div style={styles.flex}>
                                <div style={{flex: 1}}>
                                    <div><Typography variant="h5" style={{padding: '0.5em'}}>Packages</Typography>
                                    </div>
                                    <CardContent>
                                    <Typography variant='small'>Including manual payments </Typography>
                                        <div>
                                            <Grid style={{flex: 1}}>
                                                <Typography><b>Basic:</b> {state?.packages?.basic || 0} ({state?.competitionPackages?.basic?.quantity} available)</Typography>
                                                <Typography><b>Premium:</b> {state?.packages?.premium || 0} ({state?.competitionPackages?.premium?.quantity} available)</Typography>
                                                <Typography><b>VIP:</b> {state?.packages?.vip || 0} ({state?.competitionPackages?.vip?.quantity} available)
                                                </Typography>
                                            </Grid>
                                        </div>
                                    </CardContent>
                                </div>
                                <PackagesChart packages={state?.packages}/>
                            </div>
                        </Card>}
                    </div>
                </div>
                <div style={styles.rightCol}>
                    <div style={styles.flex}>
                        {!isDuringAndAfterCompetition && <Card
                            style={styles.cardStyle}>
                            <CardHeader title="Locations"></CardHeader>
                            <CardContent>
                                <List>
                                    <ListItem>
                                        <PickupLocationListItem state={Intl.NumberFormat('ro-RO').format(state)} location={"The Office"}/>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText
                                            primary={`Curier: ${Intl.NumberFormat('ro-RO').format(state?.pickupLocations && state?.pickupLocations["Curier"] || 0)}`}/>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText
                                            primary={`no pickup needed: ${Intl.NumberFormat('ro-RO').format(state?.pickupLocations && state?.pickupLocations["no pickup needed"] || 0)}`}/>
                                    </ListItem>
                                </List>
                            </CardContent>
                        </Card>}
                        {isDuringAndAfterCompetition && <Card
                            style={styles.cardStyle}>
                            <CardHeader title="Steps"></CardHeader>
                            <CardContent>
                                <Typography>Total steps: {Intl.NumberFormat('ro-RO').format(state?.totalSteps || 0)}</Typography>
                            </CardContent>
                        </Card>
                        }
                    </div>
                </div>
            </div>
            <div style={styles.singleCol}>
                {!isDuringAndAfterCompetition && <Card
                    style={styles.cardStyle}>
                    <CardHeader title="Payments"></CardHeader>
                    <CardContent>
                        <Typography variant="small">Manually activated payments aren't taken into
                            consideration.</Typography>
                        <PaymentsTimelineChart dailyPayments={state?.dailyPayments}/>
                    </CardContent>
                </Card>}

                {/*{isDuringAndAfterCompetition && <Card*/}
                {/*    style={styles.cardStyle}>*/}
                {/*    <CardHeader title="Daily Steps"></CardHeader>*/}
                {/*    <CardContent>*/}
                {/*        <StepsTimelineChart dailySteps={state?.dailySteps}/>*/}
                {/*    </CardContent>*/}
                {/*</Card>}*/}
            </div>
        </div>
    );
};

export const Dashboard = (props) => (
    <StatsGrid/>
)
