import {competitionConfig} from "../competitionConfig";
import {useDataProvider} from "react-admin";

export const checkTeamName = async (teamName) => {
    try {
        const response = await fetch(
            'https://us-central1-walkinthepark-3c783.cloudfunctions.net/checkTeamName?name=' + teamName
        );
        const json = await response.json();
        if (json.status && json.status !== 'found') {
            return undefined;
        } else {
            return "Team name is used";
        }
    } catch (error) {
        return "Error when checking for team name";
    }
}

export const teamValidation = async (values) => {

    const dataProvider = useDataProvider();
    let errors = {}

    if (values.members) {
        const {data: memberDetails} = await dataProvider.getMany(
            `${competitionConfig.path}/users`,
            {
                ids: values?.members?.map((member) => member.uid),
            });

        memberDetails?.forEach((member) => {
            if (member.team && member.team !== values.id) {
                if (!errors.members) errors.members = "";
                errors.members += `User ${member.id} has a team allocated.`;
            }
        })
    }

    return errors;
}

export const teamMemberValidation = (value, allvalues) => {
    const dataProvider = useDataProvider();
    const {team: memberDetails} = dataProvider.getOne(
        `${competitionConfig.path}/teams`,
        {
            id: value
        }).then((member) => {
        if (member.get('team') && member.get('team') !== allvalues.id) {
            return "User already has a team allocated."
        }
    })

}
export const removeFromTeams = async (memberIds, dataProvider) => {
    const {data: teamMembersData} = await dataProvider.getMany(
        `${competitionConfig.path}/users`,
        {
            ids: memberIds
        }
    );

    // remove users from teams
    teamMembersData.forEach((member) => {
        if (member && member.team) {
            const {data: teamData} = removeFromTeam(member, dataProvider);
        }
    })
}

export const removeFromTeam = async (member, dataProvider) => {
    let teamId = member.team;
    let memberId = member.id;

    const {data: team} = await dataProvider.getOne(
        `${competitionConfig.path}/teams/`,
        {
            id: teamId
        });

    let leader = team.leader;

    // if the user is a leader in that team, then remove the leader from that team
    if (leader === memberId) {
        console.log(`${teamId} doesn't have a leader anymore`);

        leader = "";
    }

    // remove user from team
    let filteredMembers = team.members.filter((member) => member.uid !== memberId);
    return await dataProvider.update(
        `${competitionConfig.path}/teams`,
        {
            id: teamId,
            data: {
                ...team,
                members: filteredMembers,
                leader: leader
            }
        }
    )
}
