import * as React from "react";
// tslint:disable-next-line:no-var-requires
import {Edit, SelectInput, SimpleForm} from "react-admin";
import {Typography} from "@material-ui/core";
import {packageAmountList, packagesList} from "../common/packages-helper";

export const UserPaymentEdit = (props) => (
    <Edit {...props}>
        <SimpleForm>
            <Typography variant="h5">Change payment</Typography>

            <SelectInput source="status" choices={[
                {id: 'confirmed', name: 'Confirmed'},
                {id: 'canceled', name: 'Canceled'},
            ]}/>
            <SelectInput
                source="processedAmount"
                choices={packageAmountList}
            />
            <SelectInput
                source="package"
                choices={packagesList}
            />
        </SimpleForm>
    </Edit>
);
