import * as React from "react";
// tslint:disable-next-line:no-var-requires
import {Create, SelectInput, SimpleForm, TextInput, useDataProvider, useRedirect, useRefresh} from "react-admin";
import {competitionConfig} from "../competitionConfig";
import {Typography} from "@material-ui/core";
import {packageAmountList, packagesList} from "../common/packages-helper";


export const UserPaymentCreate = (props) => {
    const refresh = useRefresh();
    const redirect = useRedirect();
    const dataProvider = useDataProvider();

    const onSuccess = ({data}) => {
        dataProvider.getOne(
            `${competitionConfig.path}/users/`, {id: data.id})
            .then((user) => {
                console.log(user)
                if (user) {
                    console.log("update");
                    dataProvider.update(
                        `${competitionConfig.path}/users`,
                        {
                            id: data.id,
                            data: {
                                status: 'confirmed'
                            }
                        }).then(() => {
                        redirect(`/${competitionConfig.path}/users`);
                        refresh();
                    })
                } else {
                    console.log("create");
                    dataProvider.create(`${competitionConfig.path}/users`,
                        {
                            id: data.id,
                            data: {
                                status: 'confirmed'
                            }
                        }).then(() => {
                        redirect(`/${competitionConfig.path}/users`);
                        refresh();
                    })
                }
            }).catch(error => {
            console.log("error" + error)

            dataProvider.create(`${competitionConfig.path}/users`,
                {
                    id: data.id,
                    data: {
                        id: data.id,
                        status: 'confirmed'
                    }
                }).then(() => {
                redirect(`/${competitionConfig.path}/users`);
                refresh();
            })
        })
    };

    return (
        <Create {...props} onSuccess={onSuccess}>
            <SimpleForm>
                <Typography variant="h5">Manually create payment</Typography>

                <TextInput source="id" disabled={true}/>
                <SelectInput source="status" choices={[
                    {id: 'confirmed', name: 'Confirmed'},
                    {id: 'canceled', name: 'Canceled'},
                ]}/>
                <SelectInput
                    source="processedAmount"
                    choices={packageAmountList}
                />
                <SelectInput
                    source="package"
                    choices={packagesList}
                />
            </SimpleForm>
        </Create>
    );
};
