import {createTheme} from '@material-ui/core/styles';

export const walkingMonthTheme = createTheme({
    palette: {
        type: 'dark', // Switching the dark mode on is a single property value change.

        primary: {
            main: '#33bfff',
            contrastText: '#fff',
            dark: '#33bfff',
            error: '#33bfff'
        },
        secondary: {
            light: '#33bfff',
            main: '#212331',
            dark: '#212331',
            text: '#ffffff',
            contrastText: '#ffffff',
        },
    },
    overrides: {
        MuiCssBaseline: {
            '@global': {
                body: {
                    backgroundColor: '#212331',
                    margin: 0,
                },
            },
        }
    }
});
