import dayjs from "dayjs";

export const isDuringAndAfterCompetition = () => {
    let currentDate = dayjs();

    return currentDate.diff(dayjs(competitionConfig.startDate), "d") >= 0;
}
export const isAfterCompetition = () => {
    let currentDate = dayjs();
    return currentDate.diff(dayjs(competitionConfig.endDate), "d") >= 0;
}
export const isDuringTeamCreation = () => {
    let currentDate = dayjs();
    return currentDate.diff(dayjs(competitionConfig.teamCreationStart), "d") > 0;
}

export const competitionConfig = {
    path: 'competitions/2024',
    name: '2024',
    year: '2024',
    startDate: "2024-09-23",
    endDate: "2024-10-22",
    teamCreationStart: "2024-08-19",
    isDuringTeamCreation: isDuringTeamCreation,
    isDuringAndAfterCompetition: isDuringAndAfterCompetition,
    isAfterCompetition: isAfterCompetition,
}

// export const functionsEndpoint = 'http://localhost:5001/walkinthepark-3c783/us-central1/';
export const functionsEndpoint = 'https://us-central1-walkinthepark-3c783.cloudfunctions.net/';
