import * as React from "react";
// tslint:disable-next-line:no-var-requires
import {BooleanInput, Edit, FormTab, ReferenceField, SelectInput, TabbedForm, TextField, TextInput} from "react-admin";
import {competitionConfig} from "../competitionConfig";
import {Typography} from "@material-ui/core";
import {StepsEdit} from "../steps/StepsEdit";

const onSuccess = async ({data}) => {
    // debugger;
}
export const CompetitionUserEdit = (props) => (
    <Edit {...props} onSuccess={onSuccess} mutationMode="pessimistic">
        <TabbedForm>
            <FormTab label="meta">
                <Typography variant="h5">Metadata for this year</Typography>

                <TextInput source="id" options={{disabled: true}}/>
                <ReferenceField source="id"
                                reference="users"
                                label="Name">
                    <TextField source="name" options={{disabled: true}}/>
                </ReferenceField>
                <ReferenceField source="id"
                                reference="users"
                                label="Email">
                    <TextField source="email"/>
                </ReferenceField>
                <BooleanInput label="Companies Challenge" source="companiesChallenge" defaultValue={false}/>
            </FormTab>

            <FormTab label="payments">
                <ReferenceField source="id" reference={`${competitionConfig.path}/user-payments`}
                                label={`Package`} allowEmpty link={() => ``}>
                    <TextField source="package"/>
                </ReferenceField>
                <SelectInput source="status"
                             choices={[
                                 {id: 'confirmed', name: 'Confirmed'},
                                 {id: 'canceled', name: 'Canceled'}
                             ]}/>

                <TextInput multiline source="pickupLocation" fullWidth/>
            </FormTab>
            <FormTab label="Team">
                <TextInput source="team"/>
                <Typography addLabel label="Random team"/>
                <BooleanInput label="Random Team" source="randomTeam" defaultValue={false}/>
            </FormTab>
            <FormTab label="Steps">
                <Typography addLabel label="Daily steps"/>
                <StepsEdit {...props} parentState/>
            </FormTab>
        </TabbedForm>
    </Edit>
);
