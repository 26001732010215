// in src/Menu.js
import * as React from 'react';
import {DashboardMenuItem, MenuItemLink} from 'react-admin';
import {competitionConfig} from "./competitionConfig";
import {AccountBalanceWallet, Business, Group, Person, Receipt, EmojiEvents, Lock} from "@material-ui/icons";

export const Menu = () => (
    <div style={{marginTop: 20}}>
        <DashboardMenuItem/>
        <br/>
        <MenuItemLink to="/users" primaryText="Users" leftIcon={<Person/>}/>
        <MenuItemLink to={`/${competitionConfig.path}/users`} primaryText="Competition users" leftIcon={<EmojiEvents/>}/>
        <MenuItemLink to={`/${competitionConfig.path}/teams`} primaryText="Teams" leftIcon={<Group/>}/>
        <br/>
        <MenuItemLink to={`/companies-challenge`} primaryText="Companies Challenge" leftIcon={<Business/>}/>
        <br/>
        <MenuItemLink to={`/${competitionConfig.path}/payments`} primaryText="Payments"
                      leftIcon={<Receipt/>}/>
        <MenuItemLink to={`/${competitionConfig.path}/user-payments`} primaryText="Payments per user"
                      leftIcon={<AccountBalanceWallet/>}/>
        <br/>
        <MenuItemLink to={`/${competitionConfig.path}/activation-codes`} primaryText="Activation codes" leftIcon={<Lock/>}/>
    </div>
);
