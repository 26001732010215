import React, {useCallback, useEffect, useState,} from 'react';
import {useDataProvider, useVersion} from "react-admin";
import {competitionConfig} from "../competitionConfig";
import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Card,
    CardHeader,
    CardContent,
    Grid,
    Typography
} from "@material-ui/core";
import {DataGrid, GridToolbarContainer, GridToolbarExport} from '@mui/x-data-grid';

const CustomToolbar = () => {
    return (
        <GridToolbarContainer>
            <GridToolbarExport style={{align: 'right'}} csvOptions={{allColumns: true}}/>
        </GridToolbarContainer>
    );
}

const CompanyUsersList = () => {
    const version = useVersion();
    const dataProvider = useDataProvider();
    const [data, setData] = useState([]);
    const [stats, setStats] = useState({});
    const [company, setCompany] = useState('');

    useEffect(() => {
        const newStats = {};
        data.forEach(user => {
            if (typeof user.totalSteps !== 'number') {
                console.log(user);
                user.totalSteps = 0;
            }

            if (!newStats[user.company]) {
                newStats[user.company] = {
                    users: 1,
                    index: 1,
                    steps: parseInt(user.totalSteps),
                    indexedSteps: parseInt(user.totalSteps),
                    averageSteps: parseInt(user.totalSteps),
                    indexedAverageSteps: parseInt(user.totalSteps),
                }
            } else {
                let index = 1 + 0.1 * Math.floor(newStats[user.company].users/30);

                newStats[user.company].users = newStats[user.company].users + 1;
                newStats[user.company].index = index;
                newStats[user.company].steps = newStats[user.company].steps + parseInt(user.totalSteps);
                newStats[user.company].indexedSteps = newStats[user.company].steps * index;
                newStats[user.company].averageSteps = Math.round(newStats[user.company].steps / newStats[user.company].users);
                newStats[user.company].indexedAverageSteps = Math.round(newStats[user.company].indexedSteps / newStats[user.company].users);
            }
        })

        setStats(newStats)
    }, [data])

    const columns = [
        {
            field: 'id',
            headerName: 'ID',
            width: 300
        },
        {
            field: 'name',
            headerName: 'Name',
            width: 200,
        }, {
            field: 'email',
            headerName: 'Email',
            width: 200,
        },
        {
            field: 'company',
            headerName: 'Company',
            width: 200,
        },
        {
            field: 'totalSteps',
            headerName: 'Total Steps',
            type: 'number',
            valueFormatter: (params) => {
                // from 100,000 transform to 100000
                const valueFormatted = Intl.NumberFormat('ro-RO').format(params.value || 0);
                return `${valueFormatted}`;
            },
            width: 200,
        }
    ];

    const fetchData = useCallback(async () => {
        // use node cache and cron

        const {data: competitionUsers} = await dataProvider.getList(
            `${competitionConfig.path}/users`, {
                pagination: {page: 1, perPage: 4000},
                sort: {field: 'totalSteps', order: 'DESC'},
                filter: {companiesChallenge: true},
            });

        let usersMap = {};

        competitionUsers.forEach((user) => {
            if (user?.id) {
                usersMap[user.id] = user;
            }
        })

        const {data: users} = await dataProvider.getMany(
            `users`, {
                ids: Object.keys(usersMap),
                pagination: {page: 1, perPage: 4000}
            });

        users.forEach((user) => {
            usersMap[user.id] = {...usersMap[user.id], ...user};
        });

        setData(Object.values(usersMap));
    }, [dataProvider]);

    useEffect(() => {
        fetchData();
    }, [version]);

    const handleChange = (event) => {
        setCompany(event.target.value);
    }

    return (
        <>
            <FormControl style={{marginBottom: 30}}>
                <InputLabel id="company" style={{maxWidth: 200}}>Company</InputLabel>
                <Select
                    style={{maxWidth: 300}}
                    value={company}
                    onChange={handleChange}
                >
                    <MenuItem value=''>None</MenuItem>
                    <MenuItem value='Accesa'>Accesa</MenuItem>
                    <MenuItem value='ACS Industries Europe'>ACS Industries Europe</MenuItem>
                    <MenuItem value='Archive360 Soft'>Archive360 Soft</MenuItem>
                    <MenuItem value='ATU Consulting'>ATU Consulting</MenuItem>
                    <MenuItem value='Banca Transilvania'>Banca Transilvania</MenuItem>
                    <MenuItem value='Betfair Romania Development'>Betfair Romania Development</MenuItem>
                    <MenuItem value='BPGC ✓'>BPGC ✓</MenuItem>
                    <MenuItem value='Broadridge'>Broadridge</MenuItem>
                    <MenuItem value='Colors in Projects'>Colors in Projects</MenuItem>
                    <MenuItem value='CoSoSys (now part of Netwrix)'>CoSoSys (now part of Netwrix)</MenuItem>
                    <MenuItem value='eggs unimedia romania'>eggs unimedia romania</MenuItem>
                    <MenuItem value='Emerson'>Emerson</MenuItem>
                    <MenuItem value='EuroGsm'>EuroGsm</MenuItem>
                    <MenuItem value='fischer fixings Romania'>fischer fixings Romania</MenuItem>
                    <MenuItem value='Foundever'>Foundever</MenuItem>
                    <MenuItem value='Goodwill Consulting'>Goodwill Consulting</MenuItem>
                    <MenuItem value='Groupama Asigurari'>Groupama Asigurari</MenuItem>
                    <MenuItem value='HEITS Digital'>HEITS Digital</MenuItem>
                    <MenuItem value='IkonSoft'>IkonSoft</MenuItem>
                    <MenuItem value='Iron Mountain'>Iron Mountain</MenuItem>
                    <MenuItem value='JIBE'>JIBE</MenuItem>
                    <MenuItem value='jpard Solutions'>jpard Solutions</MenuItem>
                    <MenuItem value='KÉSZ Romania'>KÉSZ Romania</MenuItem>
                    <MenuItem value='Lisam Systems'>Lisam Systems</MenuItem>
                    <MenuItem value='Machinations'>Machinations</MenuItem>
                    <MenuItem value='MDPI Romania'>MDPI Romania</MenuItem>
                    <MenuItem value='Mindera Software Craft'>Mindera Software Craft</MenuItem>
                    <MenuItem value='Montran'>Montran</MenuItem>
                    <MenuItem value='msg systems Romania'>msg systems Romania</MenuItem>
                    <MenuItem value='Net Brinel'>Net Brinel</MenuItem>
                    <MenuItem value='Nexttech'>Nexttech</MenuItem>
                    <MenuItem value='Promelek'>Promelek</MenuItem>
                    <MenuItem value='QIAGEN'>QIAGEN</MenuItem>
                    <MenuItem value='retailsolutions'>retailsolutions</MenuItem>
                    <MenuItem value='RIA Solutions Group'>RIA Solutions Group</MenuItem>
                    <MenuItem value='Routier European Transport'>Routier European Transport</MenuItem>
                    <MenuItem value='RWS'>RWS</MenuItem>
                    <MenuItem value='SIGNAL IDUNA'>SIGNAL IDUNA</MenuItem>
                    <MenuItem value='Steelcase'>Steelcase</MenuItem>
                    <MenuItem value='Studio Firefly'>Studio Firefly</MenuItem>
                    <MenuItem value='TechQuarter  Software Solutions'>TechQuarter  Software Solutions</MenuItem>
                    <MenuItem value='un-doi Centru de plati'>un-doi Centru de plati</MenuItem>
                    <MenuItem value='UNIQA Software Services'>UNIQA Software Services</MenuItem>
                    <MenuItem value='Viking Romania'>Viking Romania</MenuItem>
                    <MenuItem value='VOS LOGISTICS ROMANIA'>VOS LOGISTICS ROMANIA</MenuItem>
                    <MenuItem value='Wavestone'>Wavestone</MenuItem>
                    <MenuItem value='WeAsWeb'>WeAsWeb</MenuItem>
                    <MenuItem value='Webbeds Services'>Webbeds Services</MenuItem>
                    <MenuItem value='Westernacher Consulting'>Westernacher Consulting</MenuItem>
                    <MenuItem value='XITING'>XITING</MenuItem>
                    <MenuItem value='Yardi'>Yardi</MenuItem>
                    <MenuItem value='ZegaSoftware'>ZegaSoftware</MenuItem>
                </Select>
            </FormControl>
            {company && <div style={{display: 'flex'}}>
                <Card
                    style={{
                        width: '100%',
                        margin: '0.2em',
                        display: 'inline-block',
                        verticalAlign: 'top'
                    }}>
                    <CardContent>
                        <Grid>
                            <Typography><b>Total users:</b> {Intl.NumberFormat('ro-RO').format(stats?.[company]?.users || 0)}</Typography>
                            <Typography><b>Index:</b> {Intl.NumberFormat('ro-RO').format(stats?.[company]?.index || 0)}</Typography>
                            <Typography><b>Total steps:</b> {Intl.NumberFormat('ro-RO').format(stats?.[company]?.steps || 0)}</Typography>
                            <Typography><b>Indexed total steps:</b> {Intl.NumberFormat('ro-RO').format(stats?.[company]?.indexedSteps || 0)}</Typography>
                            <Typography><b>Average steps:</b> {Intl.NumberFormat('ro-RO').format(stats?.[company]?.averageSteps || 0)}</Typography>
                            <Typography><b>Indexed average steps:</b> {Intl.NumberFormat('ro-RO').format(stats?.[company]?.indexedAverageSteps || 0)}</Typography>
                        </Grid>
                    </CardContent>
                </Card>
            </div>}
            <DataGrid
                rows={data}
                columns={columns}
                pageSize={50}
                rowsPerPageOptions={[5]}
                filterModel={{
                    items: [{columnField: 'company', operatorValue: 'contains', value: company}],
                }}
                components={{
                    Toolbar: CustomToolbar,
                }}
            />
        </>
    );
};

export const CompaniesChallenge = (props) => (
    <CompanyUsersList/>
)
